<template>

<div class="limit-stop">
    <el-form ref="form" :model="form" label-width="100px" :rules="rules">
        
        <el-form-item label="操作类型：">
            <el-select v-model="form.action" required>
                <el-option label="新增达量断网" value="1"></el-option>
                <el-option label="修改达量断网" value="2"></el-option>
                <el-option label="取消达量断网" value="3"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="断网阈值：" required>
            <el-input v-model="form.quota"></el-input>
            <p>（只能输入数字，单位：M，-1表示无限制）</p>
        </el-form-item>
        <el-form-item label="断网类型：" required>
            <el-select v-model="form.type">
                <el-option label="用户总使用量" value="1"></el-option>
                <el-option label="超出套餐外使用量" value="2"></el-option>
            </el-select>
            <span>（用户级，阈值次月恢复；套餐级，阈值次周期恢复）</span>
        </el-form-item>
        
        <el-form-item>
            <el-button type="primary" class="themed-button" @click="sure('form')">确定</el-button>
            <el-button type="primary" class="themed-button" @click="reset('form')">重置</el-button>
            <el-button type="primary" class="themed-button" @click="close">取消</el-button>
        </el-form-item>
    </el-form>
</div>

</template>

<script type="text/ecmascript-6">
import {limitStop_} from '@/api/cardManagement/cardBillDetail.js'

import {messageBox} from '@/utils/common.js'

export default {
    props:['iccidMark'],
    data(){
        return {
            form:{
               action:'' ,
               quota:'' ,
               type:'',
               iccidMark:this.iccidMark
            },
            rules:{
                    action:[
                        { required: true, message: '操作类型不能为空', trigger: 'change' }
                    ],
                    quota:[
                        { required: true, message: '断网阈值不能为空', trigger: 'blur' }
                    ],
                    type:[
                        { required: true, message: '断网类型不能为空', trigger: 'change' }
                    ],
                },
        }
    },
    methods:{
        sure(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    limitStop_(this.form).then((res)=>{
                        console.log(res,68)
                        if(res.system_result_key=="0"){
                            messageBox(this,res.system_result_message_key);
                            this.close()
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        reset(formName){
            this.$refs[formName].resetFields();
        },
        close(){
            this.$emit("close")
        }
    }
}
</script>
