<template>
  <div class="updateExpire table-hook " style="max-height:600px;overflow:auto">
    <div style="margin-bottom:8px;">
        <el-checkbox v-model="checked" value="1" @change="refresh">隐藏今天之前的过期时间</el-checkbox>
        <el-button type="primary" class='themed-button' @click="delectSome" style="margin-left:10px;">批量删除</el-button>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="iccidMark" label="卡号" width="180px"></el-table-column>
      <el-table-column prop="phone" label="接入号" width="130px"> </el-table-column>
      <el-table-column prop="mealName" label="套餐" ></el-table-column>
      <el-table-column label="次月生效">
          <template slot-scope="scope">
              <span v-if="scope.row.type && scope.row.type===999">是</span>
              <span v-else>否</span>
          </template>
      </el-table-column>
      <el-table-column prop="activeStrategy" label="虚量规则" >
          <template slot-scope="scope">
              <span v-if="scope.row.activeStrategy == 1">A</span> 
              <span v-if="scope.row.activeStrategy == 2">B</span> 
          </template>
      </el-table-column>
      <el-table-column label="增加已使用流量百分比" >
          <template slot-scope="scope">
              <el-input v-model="scope.row.curStrategy"></el-input>
          </template>
      </el-table-column>
      <el-table-column label="开始时间" width="140">
          <template slot-scope="scope">
              <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss" v-model="scope.row.startDate"></el-date-picker>
          </template>
      </el-table-column>
      <el-table-column label="过期时间" width="140" >
          <template slot-scope="scope">
              <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss" v-model="scope.row.expireDate"></el-date-picker>
          </template>
      </el-table-column>
      <!-- <el-table-column label="备注">
          <template slot-scope="scope">
              <el-input type="textarea" autosize v-model="scope.row.remark"></el-input>
          </template>
      </el-table-column> -->
      <el-table-column label="操作" width="140px" >
          <template slot-scope="scope">
              <el-button type="primary" class='themed-button' @click="save(scope.row)">保存</el-button>
              <el-button type="danger" class='themed-button' @click="delect(scope.row)">删除</el-button>
              <!-- <el-button type="primary" class='themed-button' @click="changeVirtual(scope.row.activeStrategy)">修改虚量</el-button> -->
          </template>
      </el-table-column>
    </el-table>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        :total="total"
        layout="total, sizes, prev, next, jumper">
    </el-pagination>

    <el-dialog title="切换量级级别" :visible.sync="dialogSwitch" v-if="dialogSwitch" append-to-body> 
        <el-form label-position="right" label-width="100px" v-loading="switchLoading">
            <el-form-item label="虚量选择:" required>
                <el-radio-group v-model="strategyType">
                    <el-radio label="1">A</el-radio>
                    <el-radio label="2">B</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>  
        <div class="align-right">
            <el-button type="primary" class="themed-button" @click="sureSwitch">确定</el-button>
            <el-button type="primary" class="themed-button" @click="dialogSwitch=false">取消</el-button>
        </div>
      </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import {changeVirtualStrategy,listCardLife,updateOrDeleteCardLife,delAll} from '@/api/cardManagement/cardBillDetail.js'
import {messageBox,confirmBox} from '@/utils/common.js'

export default {
    props:['cardId','iccidMark'],
    data(){
        return {
            checked:true,
            multipleSelection:[],
            tableData:[],
            currentPage:1,
            pageSize:15,
            total:0,
            dialogSwitch:false,
            activeStrategy:'',
            strategyType:'',
            switchLoading:false
        }
    },
    mounted(){
        this._listCardLife()
    },
    methods:{
        changeVirtual(strategyType){
            this.activeStrategy = strategyType + ''
            this.strategyType = strategyType + ''
            this.dialogSwitch = true
        },
        sureSwitch(){
            if(this.activeStrategy == this.strategyType){
                this.dialogSwitch = false
                return 
            }
            let params = {
                iccidMark:this.iccidMark,
                strategyType:this.strategyType 
            }
            this.switchLoading = true
            changeVirtualStrategy(params).then((res)=>{
                this.dialogSwitch = false
                this.switchLoading = false
                messageBox(this,'切换虚量成功')
                this._listCardLife()
                this.$emit('refresh')
            })
        },
        refresh(){
            this._listCardLife()
        },
        delectSome(){
            confirmBox(this,"是否删除选中数据?","操作提示",this._delAll)
        },
        save(data){
            if(!!data.startDate && !!data.expireDate){
                let startDate =  new Date(data.startDate.replace(/\-/g, "\/")).getTime()
                let expireDate =  new Date(data.expireDate.replace(/\-/g, "\/")).getTime()
                if(startDate > expireDate){
                    messageBox(this,'开始时间不能超过过期时间')
                    return 
                }
            }
            confirmBox(this,"确定要保存吗？","操作提示",()=>{
                this.comfirOperate(data,false)
            })
        },
        delect(data){
            confirmBox(this,"确定要删除吗？","操作提示",()=>{
                this.comfirOperate(data,true)
            })
        },
        comfirOperate(data,type){
            let params = {}
            if(this.checked){
                params.hideBeforeToday  = 1
            }
            params.cardLifeId = data.id
            params.cardId = data.cardId
            if(type){
                params.delete = true
            }else{ //保存
                console.log(data)
                params.expireDate = data.expireDate
                params.remark = data.remark
                params.newStartDate = data.startDate
                let curStrategy = data.curStrategy
                params.curStrategy = curStrategy
                params.delete = false
                if (curStrategy != null && curStrategy !== '') {
                    if (isNaN(curStrategy)) {
                        var pairs = curStrategy.split(";")
                        for (var pair of pairs) {
                            var arr = pair.split(",")
                            if (arr.length < 1 || isNaN(arr[0]) || isNaN(arr[1])) {
                                messageBox(this,"增加已使用流量百分比值输入有误")
                                return 
                            }
                        }
                    }
                }
            }
            updateOrDeleteCardLife(params).then((res)=>{
                console.log(res.system_result_message_key)
                messageBox(this,res.system_result_message_key,'提示',this._listCardLife)
            })
        },
        handleSelectionChange(val){
            this.multipleSelection = val;            
        },
        handleSizeChange(val){
            this.pageSize = val
            this._listCardLife()
        },
        handleCurrentChange(){
            this.currentPage = val
            this._listCardLife()
        },
        _delAll(){
            console.log(this.multipleSelection)
            if(this.multipleSelection.length<=0){
                return 
            }
            let cardLifeIds = ''
            let cardId = this.cardId
            for(let i = 0;i<this.multipleSelection.length;i++){
                cardLifeIds += this.multipleSelection[i]["id"] +','
            }
            delAll({cardLifeIds,cardId}).then((res)=>{
                let mag = "删除成功: "+res.sucessNum+' , 删除失败: '+res.failNum
                messageBox(this,mag,'提示',this._listCardLife)
            })
        },
        _listCardLife(){
            let params={}
            if(this.checked){
                params.hideBeforeToday  = 1
            }
            params.cardId = this.cardId
            params.pageSize = this.pageSize
            params.pageNo = this.currentPage
            listCardLife(params).then((res)=>{
                this.total = res.total
                this.pageSize = res.pageSize
                this.currentPage = res.pageNo
                this.tableData = res.rows
            })
        }
    }
};
</script>

<style>
.updateExpire .el-input--prefix .el-input__inner{padding-left:28px}
.updateExpire .el-input--suffix .el-input__inner{padding-right:5px;}
.updateExpire .el-date-editor.el-input,
.updateExpire .el-date-editor.el-input__inner{width:120px;}
</style>
