<template>
  <div class="flowDetail" style="max-height:600px;overflow:auto">
     <el-tabs v-model="activeName" >
    <el-tab-pane label="已用流量" name="first">
       <div class="search">
      <el-form ref="form" :model="form" :inline="true">
        <el-form-item label="流量日期:">
          <el-col :span="11">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              v-model="form.startDate"
            ></el-date-picker>
          </el-col>
          <el-col class="line" :span="2">-</el-col>
          <el-col :span="11">
            <el-date-picker
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              v-model="form.endDate"
            ></el-date-picker>
          </el-col>
        </el-form-item>

        <el-form-item class="right">
          <el-button
            type="primary"
            class="themed-button"
            icon="el-icon-search"
            @click="search()"
            >搜索查询</el-button
          >
          <el-button
            type="primary"
            class="themed-button"
            icon="el-icon-back"
            @click="exportExcel()"
            >导出Excel</el-button
          >
        </el-form-item>
      </el-form>

      <el-table :data="tableData">
        <el-table-column prop="iccidMark" label="sim卡号"> </el-table-column>

        <el-table-column prop="flowDate" label="流量日期"> </el-table-column>

        <el-table-column prop="virtualFlow" label="已用流量"> </el-table-column>

        <el-table-column prop="usedFlow" label="已用流量(不含虚量)" v-if="currentRole==0"> </el-table-column>

        <el-table-column prop="usedVoice" label="已用语音流量"> </el-table-column>

        <el-table-column prop="createDate" label="查询时间"> </el-table-column>
        <el-table-column  label="操作" v-if="currentRole==0">  
                  <template slot-scope="scope">
                  <el-button type="primary" class="themed-button" @click="deleteRecordOne(scope.row.id,scope.row.iccidMark,scope.row.flowDateStr,scope.row.flowDate)">删除</el-button>
              </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        :total="total"
        layout="total, sizes, prev, next, jumper"
      >
      </el-pagination>
    </div>
    </el-tab-pane>
    <el-tab-pane label="累计流量" name="second" v-if="operType!=11&&currentRole==0">
         <div class="search">
      <el-form ref="formOne" :model="formOne" :inline="true">
        <el-form-item label="流量日期:">
          <el-col :span="11">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              v-model="formOne.startDate"
            ></el-date-picker>
          </el-col>
          <el-col class="line" :span="2">-</el-col>
          <el-col :span="11">
            <el-date-picker
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              v-model="formOne.endDate"
            ></el-date-picker>
          </el-col>
        </el-form-item>

        <el-form-item class="right">
          <el-button
            type="primary"
            class="themed-button"
            icon="el-icon-search"
            @click="searchOne()"
            >搜索查询</el-button
          >
         
        </el-form-item>
      </el-form>

      <el-table :data="tableDataOne">
        <el-table-column prop="iccidMark" label="sim卡号"> </el-table-column>

        <el-table-column prop="usedFlow" label="已用流量"> </el-table-column>

        <el-table-column prop="flowDate" label="流量日期"> </el-table-column>

        <el-table-column prop="createDate" label="创建时间" > </el-table-column>

        <el-table-column  label="操作" v-if="currentRole==0">  
                  <template slot-scope="scope">
                  <el-button type="primary" class="themed-button" @click="deleteRecord(scope.row.id,scope.row.iccidMark)">删除</el-button>
              </template>
        </el-table-column>

       
      </el-table>

      <el-pagination
        @size-change="handleSizeChangeOne"
        @current-change="handleCurrentChangeOne"
        :current-page="currentPageOne"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSizeOne"
        :total="totalOne"
        layout="total, sizes, prev, next, jumper"
      >
      </el-pagination>
    </div>
    </el-tab-pane>
  </el-tabs>
   
  </div>
</template>

<script type="text/ecmascript-6">
import {
  listFlowDetail,
  exportFlowDetail,
  listFlowDetailTotal,
  deleteCardMonthFlowDetail,
  deleteCardMonthFlowDetailOne
} from "@/api/cardManagement/cardBillDetail.js";
import loading from "@/utils/Loading.js";
import { messageBox } from "@/utils/common.js";
import {mapGetters} from 'vuex'

export default {
  props: ["iccidMark","operType"],
  data() {
    return {
      activeName:"first",
      form: {
        startDate: "",
        endDate: "",
      },
      formOne:{
       startDate: "",
        endDate: "",
      },
      tableData: [],
      pageSize: 15,
      currentPage: 1,
      total: 0,
      tableDataOne: [],
      pageSizeOne: 15,
      currentPageOne: 1,
      totalOne: 0,
    };
  },
  mounted() {
    this._listFlowDetail();
    this._listFlowDetailTotal()
  },
  methods: {
    search() {
      this.currentPage = 1
      this._listFlowDetail();
    },
    searchOne(){
     this.currentPageOne = 1
     this._listFlowDetailTotal()
    },
    exportExcel() {
      loading.show();
      /* ../../card/exportFlowDetail.do?iccidMark=898608011919C1351506&amp;startDate=&amp;endDate=" */
      let params = {
        iccidMark: this.iccidMark,
        startDate: this.form.startDate,
        endDate: this.form.endDate,
      };
      exportFlowDetail(params).then((res) => {
        if (res.system_result_key == "0") {
          this.$alert("导出成功，请到导出记录中下载", "提示", {
            confirmButtonText: "确定",
          }).then(() => {
            this.$router.push("/tob/i_export_list");
          });
        } else {
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this._listFlowDetail();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this._listFlowDetail();
    },
     handleSizeChangeOne(val) {
      this.pageSizeOne = val;
     this._listFlowDetailTotal()
    },
    handleCurrentChangeOne(val) {
      this.currentPageOne = val;
      this._listFlowDetailTotal()
    },
    _listFlowDetail() {
      let params = Object.assign(this.form, {
        pageSize: this.pageSize,
        pageNo: this.currentPage,
        iccidMark: this.iccidMark,
      });
      listFlowDetail(params).then((res) => {
        this.currentPage = res.pageNo;
        this.pageSize = res.pageSize;
        this.total = res.total;
        this.tableData = res.rows.map((value, index, arr) => {
          return {
            iccidMark: value.iccidMark,
            flowDate: this.ftDate(value),
            usedFlow: value.usedFlow,
            usedVoice:value.usedVoice,
            createDate: this.ftDate(value),
            virtualFlow:value.virtualFlow,
            id:value.id,
            flowDateStr:value.flowDateStr,
          };
        });
      });
    },

    ftDate(rowData) {
      var date = new Date(rowData.flowDate);
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    },
    ftTime(rowData) {
      var date = new Date(rowData);
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
      );
    },
      _listFlowDetailTotal() {
      let params = Object.assign(this.formOne, {
        pageSize: this.pageSizeOne,
        pageNo: this.currentPageOne,
        iccidMark: this.iccidMark,
      });
      listFlowDetailTotal(params).then((res) => {
        this.currentPageOne = res.pageNo;
        this.pageSizeOne = res.pageSize;
        this.totalOne = res.total;
        this.tableDataOne = res.rows.map((value, index, arr) => {
          return {
            iccidMark: value.iccidMark,
            flowDate: this.ftTime(value.flowDate),
            usedFlow: value.usedFlow,
            createDate: this.ftTime(value.createDate),
            virtualFlow:value.virtualFlow,
            id:value.id,
          };
        });
      });
    },
    deleteRecord(id,iccidMark){
    this.$confirm('确定删除吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteCardMonthFlowDetail({id,iccidMark}).then(res=>{
                    this.$message({
                    type: 'success',
                    message: '删除成功!'
                    });
                    this._listFlowDetailTotal()
                })
                
            }).catch(() => {
                this.$message({
                type: 'info',
                message: '已取消'
                });          
            });
    },
      deleteRecordOne(id,iccidMark,flowDateStr,flowDate){
    this.$confirm('确定删除吗？', '提示', {
                cancelButtonText: '取消',
                 confirmButtonText: '确定',
                type: 'warning'
            }).then(() => {
                deleteCardMonthFlowDetailOne({id,iccidMark,flowDateStr,flowDate}).then(res=>{
                   this.$message({
                    type: 'success',
                    message: '删除成功!'
                    });
                    this._listFlowDetail()
                    this._listFlowDetailTotal()
                })
                
            }).catch(() => {
                this.$message({
                type: 'info',
                message: '已取消'
                });          
            });
    }
  },



  	 computed:{
        	...mapGetters(
            	['currentRole']
        	),
    	},
};
</script>

<style>
.flowDetail .line,.flowDetail th, .flowDetail td{
  text-align: center;
}

</style>
