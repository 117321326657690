<template>
    <div class="voice-card-order fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">语音白名单</span>
            <el-form inline ref="form" v-model="form" class="hook right themed-form search-form" style="line-height: 40px;">
              
                <el-form-item label="白名单:">
                    <el-input v-model="form.whiteNumber"></el-input>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>
                <el-form-item>
					<el-button type="primary" @click="addVoiceWhiteRecord" icon="el-icon-plus" class="themed-button">新增</el-button>
				</el-form-item>


                
            </el-form>
        </div>

        <div class="content-box">
            <el-table ref="table" :data="tableData" stripe border :max-height="maxTableHeight">
                <el-table-column label="ICCID" prop="iccidMark" width="180px"></el-table-column>
                <el-table-column label="接入号" prop="phone" show-overflow-tooltip></el-table-column>
                <el-table-column label="白名单" prop="whiteNumber" show-overflow-tooltip></el-table-column>
                <el-table-column label="创建时间" prop="createDate" show-overflow-tooltip></el-table-column>
                 <el-table-column label="名称" prop="tag" show-overflow-tooltip></el-table-column>
                <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" prop="" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-button class="themed-button" type="danger" @click="deleteVoiceWhiteRecord(scope.row.id)">删除</el-button>
                </template>
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
        <el-dialog title="新增白名单" :visible.sync="dialogVisible" width="500px" destroy-on-close   append-to-body>
			<el-form label-width="120px" :model="ruleForm"  ref="ruleForm">
                <el-form-item label="名称" prop="tag">
					<el-input v-model="ruleForm.tag" style="width: 240px;"></el-input>
				</el-form-item>
				<el-form-item label="白名单号码" prop="whiteNumber">
					<el-input v-model="ruleForm.whiteNumber" style="width: 240px;"></el-input>
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input v-model="ruleForm.remark" style="width: 240px;"></el-input>
				</el-form-item>
			
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button class="themed-button" type="info" @click="dialogVisible = false">取 消</el-button>
				<el-button class="themed-button" type="primary" @click="onAddRecord">确 定</el-button>
			</div>
		</el-dialog>
    </div>
</template>

<script>

import resize from '@/mixins/resize.js'
import {voiceWhitelist,addVoiceWhite,deleteVoiceWhite} from "@/api/history.js"

export default {
    name:'voiceWhitelist',
     mixins:[resize],
    props: ["iccidMark","phone"],
    data(){
        return {
            form:{
                iccidMark:'',
                whiteNumber:''
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            dialogVisible:false,
            ruleForm:{
             tag:"",
             remark:"",
             whiteNumber:"",
             iccidMark:"",
             phone:""
           },
        }
    },
    mounted(){
       
        this.form.iccidMark =  this.iccidMark
        this._voiceWhitelist()
    },
    methods: {
        deleteVoiceWhiteRecord(id){
				this.$confirm("确定要删除吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					deleteVoiceWhite({id}).then(res=>{
						this.$message({
							type: 'success',
							message:'删除成功'
						});
					})
					this._voiceWhitelist()
				})
			},
        addVoiceWhiteRecord(){
           this.dialogVisible = true
           this.ruleForm.tag =""
           this.ruleForm.remark =""
           this.ruleForm.whiteNumber =""
           this.ruleForm.iccidMark = this.iccidMark
           this.ruleForm.phone = this.phone
        },
        onAddRecord(){
        if(this.ruleForm.tag.trim() ==""){
          this.$alert('请输入名称', '提示', {
							    confirmButtonText:'确定',
							});
					return
        }
        if(this.ruleForm.whiteNumber.trim() ==""){
          this.$alert('请输入电话号码', '提示', {
							    confirmButtonText:'确定',
							});
					return
        }
        let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        if(!reg_tel.test(this.ruleForm.whiteNumber)){
           this.$alert('请输入正确的电话号码', '提示', {
							    confirmButtonText:'确定',
							});
					return
        } 

        addVoiceWhite(this.ruleForm).then(res=>{
							this.$message({
								type: 'success',
								message:'新增成功'
							});
							this.dialogVisible = false
							this._voiceWhitelist()
						})
        },
        onPageChange(page){
			this.page = page
			this._voiceWhitelist()
		},
		handleSizeChange(size){
			this.pageSize = size
			this._voiceWhitelist()
        },
        onSearch(){
            this._voiceWhitelist()
        },
        // exportExcel(){
        //     voiceWhitelistEeportSync(this.form).then(res=>{
		// 			if (res.system_result_key == '0') {
		// 				this.$alert('导出成功，请到导出记录中下载', '提示', {
		// 				    confirmButtonText:'确定',
		// 				}).then(()=>{
		// 					this.$router.push('/tob/i_export_list')
		// 				});
		// 			}else{
		// 				this.$message({
		// 				    showClose: true,
		// 				    message: res.system_result_message_key,
		// 				    type: 'error'
		// 				});
		// 			}
		// 		})
        // },
        _voiceWhitelist(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            voiceWhitelist(Object.assign(params,this.form)).then((res)=>{
                this.tableData = res.rows
                this.page = res.pageNo
                this.pageSize = res.pageSize
                this.total = res.total
            })
        }
    },
}
</script>