<template>

<div class="modifyFlow" label-width="120px">
     <el-tabs v-model="activeName" >
    <el-tab-pane label="增减真实流量" name="first">
        <el-form :model="form" :rules='rules' ref="form">
        <el-form-item label="增减真实已用流量" prop="flowSize">
            <el-input v-model="form.flowSize" ></el-input>
            <p class="red">正数为增加流量，负数为减少流量，单位为MB</p>
        </el-form-item>
        <el-form-item label="备注">
            <el-input v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item>
             <el-button type="primary" class="themed-button" @click="sure('form')">确定</el-button>
            <el-button type="primary" class="themed-button" @click="close">关闭</el-button>
        </el-form-item>
    </el-form>
    </el-tab-pane>
    <el-tab-pane label="增减虚流量" name="second">
    <el-form :model="formOne" :rules='rules' ref="formOne">
        <el-form-item label="增减虚已用流量" prop="virtualFlowSize">
            <el-input v-model="formOne.virtualFlowSize" ></el-input>
            <p class="red">正数为增加流量，负数为减少流量，单位为MB</p>
        </el-form-item>
        <el-form-item label="备注">
            <el-input v-model="formOne.remark"></el-input>
        </el-form-item>
        <el-form-item>
             <el-button type="primary" class="themed-button" @click="sureOne('formOne')">确定</el-button>
            <el-button type="primary" class="themed-button" @click="close">关闭</el-button>
        </el-form-item>
    </el-form>
    </el-tab-pane>
  </el-tabs>
    
</div>

</template>

<script type="text/ecmascript-6">

import { modifyFlow, modifyFlowOne} from "@/api/cardManagement/cardBillDetail.js";
import { messageBox } from "@/utils/common.js";

export default {
    props:['curInfor','cardId'],
    data(){
        return {
            form:{
                flowSize:0,
                remark:''
            },
             formOne:{
                virtualFlowSize:0,
                remark:''
            },
            rules:{
                flowSize:[
                    { required: true, message: '请输入流量', trigger: 'blur'}
                ],
                
                virtualFlowSize:[
                    {required:true,massage:'请输入流量',trigger:'blur'}
                ],
            },
            activeName:"first",
        }
    },
    methods:{
        sure(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this._modifyFlow()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
            
        },
          sureOne(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this._modifyFlowOne()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
            
        },
        close(){
           this.$emit('close') 
        },
        _modifyFlow(){
            let params ={}
            params.cardId = this.curInfor.id
            params.iccidOrPhone = this.curInfor.iccidMark
            params.setMealId = this.curInfor.setMealId
            params.isChild = this.curInfor.isChild
            params.codeChild = this.curInfor.codeChild
            params.flowSize = this.form.flowSize
            params.remark = this.form.remark
            modifyFlow(params).then((res)=>{
                this.close()
            })
        },
        _modifyFlowOne(){
           let params ={}
            params.cardId = this.curInfor.id
            params.iccidOrPhone = this.curInfor.iccidMark
            params.setMealId = this.curInfor.setMealId
            params.isChild = this.curInfor.isChild
            params.codeChild = this.curInfor.codeChild
            params.virtualFlowSize = this.formOne.virtualFlowSize
            params.remark = this.formOne.remark
            modifyFlowOne(params).then((res)=>{
                this.close()
            }) 
        }
    }
}
</script>
<style>
.modifyFlow .el-input {  width: auto; }
.modifyFlow .red{color:#f00;}
</style>